<!--
  Component to display a selected user in the admin screen
-->
<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('edit-user-title') }}</p>
    </header>
    <section class="modal-card-body">
      <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
      <strong>
        <p>{{ $t('edit-user-instructions') }}</p>
      </strong>
      <br />

      <p
        class="has-text-weight-semibold has-text-primary is-size-5"
        style="margin-bottom: 1em"
      >
        {{ email }}
      </p>

      <div class="flex-parent">
        <b-field
          v-if="adminAccess || superUser"
          :label="$t('user-admin-super-user')"
        >
          <b-switch v-model="superUserLocal"></b-switch>
        </b-field>

        <b-field v-if="purchasedOmw" :label="$t('user-admin-omw-viewer')">
          <b-switch v-model="omwLookupLocal"></b-switch>
        </b-field>

        <!-- <b-field v-if="purchasedOmw" :label="$t('user-admin-omw-stats')">
          <b-switch v-model="omwStatsLocal"></b-switch>
        </b-field> -->

        <b-field
          v-if="purchasedFeedback"
          :label="$t('user-admin-omw-customer-feedback')"
        >
          <b-switch v-model="feedbackLocal"></b-switch>
        </b-field>

        <b-field
          v-if="purchasedMarketing"
          :label="$t('user-admin-omw-marketing')"
        >
          <b-switch v-model="marketingLocal"></b-switch>
        </b-field>

        <b-field
          v-if="purchasedOmw"
          :label="$t('user-admin-omw-resource-config')"
        >
          <b-switch v-model="resourceConfigLocal"></b-switch>
        </b-field>

        <b-field
          v-if="customerAdminAccess"
          :label="$t('user-admin-administrator')"
        >
          <b-switch v-model="customerAdministratorLocal"></b-switch>
        </b-field>
      </div>

      <div class="has-text-centered buttons">
        <b-button type="is-primary" @click="processUserUpdate">
          {{ $t('edit-user-update-label') }}
        </b-button>

        <b-button
          v-if="customerAdminAccess || leadentAccess"
          type="is-danger"
          @click="processUserDeletion"
        >
          {{ $t('edit-user-delete-label') }}
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import { defineComponent } from '@vue/composition-api';

import {
  deletePortalUserRecord,
  updatePortalUserRecord,
} from '@/services/users/index';

export default defineComponent({
  props: {
    email: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    omwLookup: {
      type: Boolean,
      required: true,
      default: false,
    },
    omwStats: {
      type: Boolean,
      required: true,
      default: false,
    },
    feedback: {
      type: Boolean,
      required: true,
      default: false,
    },
    superUser: {
      type: Boolean,
      required: true,
      default: false,
    },
    resourceConfig: {
      type: Boolean,
      required: true,
      default: false,
    },
    marketing: {
      type: Boolean,
      required: true,
      default: false,
    },
    customerAdministrator: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      omwLookupLocal: null,
      feedbackLocal: null,
      omwStatsLocal: null,
      superUserLocal: null,
      marketingLocal: null,
      resourceConfigLocal: null,
      customerAdministratorLocal: null,
    };
  },
  watch: {
    omwLookup(newValue) {
      this.omwLookupLocal = newValue;
    },
    omwStats(newValue) {
      this.omwStatsLocal = newValue;
    },
    feedback(newValue) {
      this.feedbackLocal = newValue;
    },
    superUser(newValue) {
      this.superUserLocal = newValue;
    },
    marketing(newValue) {
      this.marketingLocal = newValue;
    },
    resourceConfig(newValue) {
      this.resourceConfigLocal = newValue;
    },
    customerAdministrator(newValue) {
      this.customerAdministratorLocal = newValue;
    },
  },
  created() {
    this.omwLookupLocal = this.omwLookup;
    this.omwStatsLocal = this.omwStats;
    this.feedbackLocal = this.feedback;
    this.superUserLocal = this.superUser || false;
    this.marketingLocal = this.marketing;
    this.resourceConfigLocal = this.resourceConfig;
    this.customerAdministratorLocal = this.customerAdministrator;
  },
  methods: {
    async processUserDeletion() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Warning',
        hasIcon: true,
        icon: 'exclamation',
        confirmText: 'Delete User',
        message: `Are you sure you wish to delete user: ${this.email}?`,
        onConfirm: async () => {
          this.isLoading = true;
          const user = {
            id: this.id,
          };

          try {
            await deletePortalUserRecord(user);
          } catch (err) {
            console.error(err);
            this.$buefy.toast.open({
              duration: 10000,
              message: `Error deleting user: ${err}`,
              type: 'is-danger',
            });
          } finally {
            this.isLoading = false;
          }
          // Call to backend to delete the firebase auth entry
          try {
            this.$emit('user-deleted', this.id);
            this.$buefy.toast.open({
              duration: 3000,
              message: 'User deleted successfully',
              type: 'is-success',
            });
          } catch (err) {
            console.error(err);
            this.$buefy.toast.open({
              duration: 10000,
              message: `Error deleting user: ${err}`,
              type: 'is-danger',
            });
          } finally {
            this.isLoading = false;
          }
        },
      });
    },
    async processUserUpdate() {
      const user = {
        sub: this.id,
        email: this.email,
        omwLookup: this.omwLookupLocal,
        omwStats: this.omwStatsLocal,
        feedback: this.feedbackLocal,
        superUser: this.superUserLocal,
        marketing: this.marketingLocal,
        resourceConfig: this.resourceConfigLocal,
        customerAdministrator: this.customerAdministratorLocal,
      };
      await updatePortalUserRecord(user);
      this.$emit('user-updated');
    },
  },
});
</script>

<style>
.flex-parent {
  width: 40vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
