<!--
  Component for the administration of user access to functionality
-->
<template>
  <div class="tile" style="padding-left: 10px">
    <b-loading :is-full-page="true" :active.sync="dataLoading"></b-loading>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile">
          <div class="tile is-child">
            <b-field :label="$t('user-admin-search-label')">
              <b-input
                v-model="searchCriteria"
                :expanded="false"
                size="is-medium"
                type="search"
                icon="search"
                @input="search()"
              ></b-input>
            </b-field>
            <div>
              <p>
                {{ $t('user-admin-user-count') }}:
                <span class="has-text-weight-semibold">{{ users.length }}</span>
              </p>
              &nbsp;
            </div>
            <b-table
              :narrowed="true"
              :hoverable="true"
              :data="dataSet"
              :paginated="true"
              :per-page="20"
              :pagination-simple="false"
              :selected.sync="selectedRow"
              default-sort="email"
              @click="showEditUser"
            >
              <b-table-column
                v-slot="props"
                field="email"
                sortable
                :label="$t('user-admin-email')"
                >{{ props.row.email }}</b-table-column
              >
              <b-table-column
                v-slot="props"
                field="leadent"
                sortable
                centered
                :label="$t('user-admin-super-user')"
              >
                <b-icon
                  :icon="getIcon(props.row.superUser)"
                  :type="getIconColor(props.row.superUser)"
                  pack="fas"
                ></b-icon>
              </b-table-column>

              <b-table-column
                v-if="purchasedOmw"
                v-slot="props"
                field="omwLookup"
                sortable
                centered
                :label="$t('user-admin-omw-viewer')"
              >
                <b-icon
                  :icon="getIcon(props.row.omwLookup)"
                  :type="getIconColor(props.row.omwLookup)"
                  pack="fas"
                ></b-icon>
              </b-table-column>

              <b-table-column
                v-if="purchasedOmwStats"
                v-slot="props"
                field="omwStats"
                sortable
                centered
                :label="$t('user-admin-omw-stats')"
              >
                <b-icon
                  :icon="getIcon(props.row.omwStats)"
                  :type="getIconColor(props.row.omwStats)"
                  pack="fas"
                ></b-icon>
              </b-table-column>

              <b-table-column
                v-if="purchasedFeedback"
                v-slot="props"
                field="feedback"
                sortable
                centered
                :label="$t('user-admin-omw-customer-feedback')"
              >
                <b-icon
                  :icon="getIcon(props.row.feedback)"
                  :type="getIconColor(props.row.feedback)"
                  pack="fas"
                ></b-icon>
              </b-table-column>

              <b-table-column
                v-if="purchasedMarketing"
                v-slot="props"
                field="marketing"
                sortable
                centered
                :label="$t('user-admin-omw-marketing')"
              >
                <b-icon
                  :icon="getIcon(props.row.marketing)"
                  :type="getIconColor(props.row.marketing)"
                  pack="fas"
                ></b-icon>
              </b-table-column>

              <b-table-column
                v-if="purchasedOmw"
                v-slot="props"
                field="marketing"
                sortable
                centered
                :label="$t('user-admin-omw-resource-config')"
              >
                <b-icon
                  :icon="getIcon(props.row.resourceConfig)"
                  :type="getIconColor(props.row.resourceConfig)"
                  pack="fas"
                ></b-icon>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="customerAdministrator"
                sortable
                centered
                :label="$t('user-admin-administrator')"
              >
                <b-icon
                  :icon="getIcon(props.row.customerAdministrator)"
                  :type="getIconColor(props.row.customerAdministrator)"
                  pack="fas"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-slot="props"
                :visible="false"
                field="key"
                sortable
                label="Key"
                >{{ props.row.key }}</b-table-column
              >
              <template v-if="users.length >= 5" slot="footer">
                <th class="is-hidden-mobile" style="width: 40px">
                  <div class="th-wrap">{{ $t('user-admin-email') }}</div>
                </th>
                <th class="is-hidden-mobile">
                  <div class="th-wrap">{{ $t('user-admin-super-user') }}</div>
                </th>

                <th v-if="purchasedOmw" class="is-hidden-mobile">
                  <div class="th-wrap">{{ $t('user-admin-omw-viewer') }}</div>
                </th>
                <th v-if="purchasedOmwStats" class="is-hidden-mobile">
                  <div class="th-wrap">{{ $t('user-admin-omw-stats') }}</div>
                </th>
                <th v-if="purchasedFeedback" class="is-hidden-mobile">
                  <div class="th-wrap">
                    {{ $t('user-admin-omw-customer-feedback') }}
                  </div>
                </th>
                <th v-if="purchasedMarketing" class="is-hidden-mobile">
                  <div class="th-wrap">
                    {{ $t('user-admin-omw-marketing') }}
                  </div>
                </th>
                <th class="is-hidden-mobile">
                  <div class="th-wrap">
                    {{ $t('user-admin-administrator') }}
                  </div>
                </th>
              </template>
            </b-table>
            <b-button type="is-primary" @click="getUsers">
              {{ $t('user-admin-omw-refresh-data') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import SelectedUser from './SelectedUser';

export default defineComponent({
  name: 'Admin',
  searchOptions: {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    keys: ['key', 'email'],
  },
  data() {
    return {
      dataLoading: false,
      selectedRow: null,
      searchCriteria: null,
      searchResults: null,
      selectedUser: null,
      theModal: undefined,
    };
  },
  computed: {
    ...mapGetters('storeAuth', ['users']),
    // Used for switching between search results in component and full results in vuex store
    dataSet() {
      if (this.searchCriteria && this.searchCriteria.length > 1) {
        return this.searchResults;
      } else {
        return this.users;
      }
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions('storeAuth', ['fetchUsers']),
    async fetchData() {
      if (this.theModal) {
        this.theModal.close();
        this.theModal = undefined;
      }
      this.selectedUser = null;
      this.dataLoading = true;
      try {
        await this.fetchUsers();
      } catch (err) {
        console.error(err);
      } finally {
        this.dataLoading = false;
      }
    },
    showEditUser(user) {
      this.selectedUser = user;
      this.theModal = this.$buefy.modal.open({
        parent: this,
        component: SelectedUser,
        props: {
          id: this.selectedUser.sub,
          email: this.selectedUser.email,
          omwLookup: this.selectedUser.omwLookup || false,
          omwStats: this.selectedUser.omwStats || false,
          feedback: this.selectedUser.feedback || false,
          superUser: this.selectedUser.superUser || false,
          marketing: this.selectedUser.marketing || false,
          resourceConfig: this.selectedUser.resourceConfig || false,
          customerAdministrator:
            this.selectedUser.customerAdministrator || false,
        },
        events: {
          'user-deleted': this.fetchData,
          'user-updated': this.fetchData,
        },
        hasModalCard: true,
      });
    },
    async getUsers() {
      this.dataLoading = true;
      try {
        await this.fetchUsers();
      } catch (err) {
        console.error(err);
      } finally {
        this.dataLoading = false;
      }
    },
    async search() {
      this.searchResults = await this.$search(
        this.searchCriteria,
        this.users,
        this.$options.searchOptions,
      );
    },
    getIcon(value) {
      if (value) {
        return 'check';
      } else {
        return 'times';
      }
    },
    getIconColor(value) {
      if (value) {
        return 'is-success';
      } else {
        return 'is-danger';
      }
    },
  },
});
</script>
