import { API } from 'aws-amplify';

export async function deletePortalUserRecord(username) {
  await API.delete('omw', `users/${username}`);
}

export async function updatePortalUserRecord(user) {
  const payload = {
    body: user,
  };
  try {
    await API.put('omw', `/users/${user.sub}`, payload);
  } catch (err) {
    console.error({ err });
    throw err;
  }
}
